import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { CreateHomeworkQuestionForAxios, CreateQuestionBankQuestionForAxios, ImportQuestionForAxios, ImportQuestionsBody, ImportQuestionsResponse } from '@/models/question_banks';
import { QuestionResponse } from '@/models/questions';

@Module({
  namespaced: true,
  dynamic: true,
  name: `question.create`,
  store,
})
class QuestionCreateStore extends VuexModule {
  isLoading = false;
  error: any | null = null;
  response: boolean | null = null;
  questionProperties: CreateQuestionBankQuestionForAxios | null = null;

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setResponse(payload: boolean | null) {
    this.response = payload;
  }

  @Mutation
  setQuestionProperties(payload: CreateQuestionBankQuestionForAxios) {
    this.questionProperties = payload;
  }

  @Action
  createNewQuestionBankQuestion(payload: Partial<CreateQuestionBankQuestionForAxios>) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    this.context.commit(`setQuestionProperties`, payload);
    axios
      .post<QuestionResponse>(`/question`, payload)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`setResponse`, response.data.data);
        }

        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating a question. Please try again. Error: ${response.data.error}`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }

  @Action
  createNewHomeworkQuestion(payload: Partial<CreateHomeworkQuestionForAxios>) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    this.context.commit(`setQuestionProperties`, payload);
    axios
      .post<QuestionResponse>(`/homeworkquestion`, payload)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`setResponse`, response.data.data);
        }

        const invalidAnswerSelectionError =
          'Coding error detected, it must be fixed by a programmer: $result->noticeyesno no longer supported in save_question.';
        if (response.data.code === 500 && response.data.error === invalidAnswerSelectionError) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `Selected Answers does not match with One or Multiple Answers Selection. Please try again`,
          });
        } else if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating a question. Please try again.`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }

  @Action
  importQuestions(payload: ImportQuestionForAxios) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    axios
      .post<ImportQuestionsResponse>(`/question/bulk-import`, payload)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`setResponse`, response.data.data);
        }

        const invalidAnswerSelectionError =
          'Coding error detected, it must be fixed by a programmer: $result->noticeyesno no longer supported in save_question.';
        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with import question file. Please try again.`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }
}

export default getModule(QuestionCreateStore);
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { FolderItem } from '@/models/miscellaneous';
import { CourseListItem, SubjectLevelListItem } from '@/models/management';
import { LectureItem } from '@/models/lectures';
import { PaginationMemory } from '@/models/generic';

@Module({
    namespaced: true,
    dynamic: true,
    name: `lecture.state`,
    store,
})
class LectureStateStore extends VuexModule {
    isLoading = false;
    isModified = false;
    error: any | null = null;
    lectureFolderState: FolderItem[] = [];
    courseData: CourseListItem[] = [];
    lectureData: LectureItem[] = [];
    formData: SubjectLevelListItem[] = [];
    currentPageState: { [key : string] : number } = {};
    currentLimitState: { [key : string] : number } = {};
    currentKeywordState: { [key : string] : string } = {};
    currentTotalCountState: { [key : string] : number } = {};

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    setIsModified(payload: boolean) {
        this.isModified = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    saveLectureFolderState(payload: FolderItem[]) {
        this.lectureFolderState = payload;
    }

    @Mutation
    saveLectureCourseState(payload: CourseListItem[]) {
        this.courseData = payload;
    }

    @Mutation
    saveLectureFormState(payload: SubjectLevelListItem[]) {
        this.formData = payload;
    }

    @Mutation
    saveLectureItemState(payload: LectureItem[]) {
        this.lectureData = payload;
    }  
    
    @Mutation
    saveLectureCurrentPage(payload: PaginationMemory) {
        this.currentPageState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveLectureCurrentLimit(payload: PaginationMemory) {
        this.currentLimitState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveLectureCurrentTotalCount(payload: PaginationMemory) {
        this.currentTotalCountState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveLectureCurrentKeyword(payload: PaginationMemory) {
        this.currentKeywordState[payload.folderLevel] = payload.value as string;
    }

    @Action
    updateLectureIsModified(payload: boolean) {
        this.context.commit(`setIsModified`, payload);
    }

    @Action
    updateLectureFolderState(payload: FolderItem[]) {
        this.context.commit(`saveLectureFolderState`, payload);
    }

    @Action
    updateLectureCourseState(payload: CourseListItem[]) {
        this.context.commit(`saveLectureCourseState`, payload);
    }

    @Action
    updateLectureFormState(payload: SubjectLevelListItem[]) {
        this.context.commit(`saveLectureFormState`, payload);
    }

    @Action
    updateLectureItemState(payload: LectureItem[]) {
        this.context.commit(`saveLectureItemState`, payload);
    }

    @Action
    updateLectureCurrentPage(payload: PaginationMemory) {
        this.context.commit(`saveLectureCurrentPage`, payload);
    }

    @Action
    updateLectureCurrentLimit(payload: PaginationMemory) {
        this.context.commit(`saveLectureCurrentLimit`, payload);
    }

    @Action
    updateLectureCurrentTotalCount(payload: PaginationMemory) {
        this.context.commit(`saveLectureCurrentTotalCount`, payload);
    }

    @Action
    updateLectureCurrentKeyword(payload: PaginationMemory) {
        this.context.commit(`saveLectureCurrentKeyword`, payload);
    }

    @Action
    resetState() {
        this.updateLectureCourseState([]);
        this.updateLectureFormState([]);
        this.updateLectureFolderState([]);
        this.updateLectureItemState([]);
        this.updateLectureIsModified(false);
        this.context.commit(`saveLectureCurrentKeyword`, {});
        this.context.commit(`saveLectureCurrentLimit`, {});
        this.context.commit(`saveLectureCurrentTotalCount`, {});
        this.context.commit(`saveLectureCurrentPage`, {});
    }
}

export default getModule(LectureStateStore);
import LectureListStore from './lecture.list';
import LectureDetailStore from './lecture.detail';
import LectureStateStore from './lecture.state';
import LectureCreateStore from './lecture.create';

export const LectureStores = {
    list: LectureListStore,
    detail: LectureDetailStore,
    state: LectureStateStore,
    create: LectureCreateStore
};
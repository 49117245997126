import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { ModerationQuestionDetails, QuestionListDataResponse, QuestionResponse } from '@/models/questions';

@Module({
    namespaced: true,
    dynamic: true,
    name: `questions.moderation`,
    store,
})
class QuestionModerationStore extends VuexModule{
    isLoading = false;
    error: any | null = null;
    response: QuestionListDataResponse | null = null;
    moderatedResponse: boolean | null = null;
    selectedQuestions: ModerationQuestionDetails[] = [];

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: QuestionListDataResponse) {
        this.response = payload;
    }

    @Mutation
    setModeratedResponse(payload: boolean) {
        this.moderatedResponse = payload;
    }

    @Mutation
    setSelectedQuestions(payload: ModerationQuestionDetails[]) {
        this.selectedQuestions = payload;
    }

    @Action
    retrieveUnmoderatedQuestions() {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        // This will not work since the API doesn't do what we need yet. Will be placed here for now
        axios.get<QuestionResponse>(`/question/moderation`).then((response) => {
            if (response.data.code === 200) {
                // response.data is the HTTP's data response.
                this.context.commit(`setResponse`, response.data.data);
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching question moderation data. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    updateQuestionsStatus(payload: ModerationQuestionDetails) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setSelectedQuestions`, payload);
        axios.post<QuestionResponse>(`/question/moderation`, payload)
            .then((response) => {
                if (response.data.code === 200) {
                    // response.data is the HTTP's data response.
                    this.context.commit(`setModeratedResponse`, response.data.data === null);
                    Toast.open({
                        type: `is-success`,
                        duration: 3000, // 3 Seconds
                        message: `Successfully ${payload.approved === 1 ? 'approved' : 'rejected'} the selected questions`,
                    });
                } 
                
                if (response.data.code === 500) {
                    Toast.open({
                        type: `is-danger`,
                        duration: 3000, // 3 Seconds
                        message: `There is an issue with ${payload.approved === 1 ? 'approved' : 'rejected'} questions. Please try again`,
                    });
                }
            })
            .catch((error) => {
                this.context.commit(`setError`, error);
            });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(QuestionModerationStore);












import { Vue, Component, Prop, Emit, Watch } from 'vue-property-decorator';
@Component({})
export default class SearchBar extends Vue {
  currentText = ``;

  @Prop({ default: `Search...` })
  placeholderText!: string;

  @Prop({ default: null })
  searchText!: string;

  @Watch(`searchText`, { immediate: true })
  updateText() {
    this.currentText = this.searchText;
  }

  @Emit(`search`)
  returnSearchText() {
    return this.currentText;
  }
}

import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { CourseListDataResponse, CourseListResponse, FetchCoursesWithParams } from '@/models/courses';
import { ToastProgrammatic as Toast } from 'buefy';

@Module({
    namespaced: true,
    dynamic: true,
    name: `courses.list`,
    store,
})
class CourseListStore extends VuexModule{
    isLoading = false;
    error: any | null = null;
    response: CourseListDataResponse | null = null;
    subjectId: number | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: CourseListDataResponse) {
        this.response = payload;
    }

    @Mutation
    setSubjectId(payload: number) {
        this.subjectId = payload;
    }

    @Action
    retrieveCoursesWithParams(payload: Partial<FetchCoursesWithParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setSubjectId`, payload.subject);
        // This will not work since the API doesn't do what we need yet. Will be placed here for now
        axios.get<CourseListResponse>(`/course`, {
            params: payload,
        }).then((response) => {
            if (response.data.code === 200) {
                // response.data is the HTTP's data response.
                response.data.data.totalCount = Number(response.headers[`x-total-records`]);
                this.context.commit(`setResponse`, response.data.data);
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching data. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    retrieveAllSiteCoursesWithParams(payload: Partial<FetchCoursesWithParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setSubjectId`, payload.subject);
        axios.get<CourseListResponse>(`/site/course?page=1&limit=100`, {
            params: payload,
        }).then((response) => {
            if (response.data.code === 200) {
                // response.data is the HTTP's data response.
                response.data.data.totalCount = Number(response.headers[`x-total-records`]);
                this.context.commit(`setResponse`, response.data.data);
            } 

            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching data. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}
export default getModule(CourseListStore);
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { ProgressCourseItem, ProgressResponse, StudentProgressSection } from '@/models/progress';

@Module({
    namespaced: true,
    dynamic: true,
    name: `progress.courses`,
    store,
})
class ProgressCourseStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: ProgressCourseItem[] = [];

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: ProgressCourseItem[]) {
        this.response = payload;
    }

    @Action
    retrieveStudentPaidCourses(studentId: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, []);

        axios.get<ProgressResponse>(`/studentprogress`, {
            params: {
                section: StudentProgressSection.purchasedCourses,
                user_id: studentId
            }
        }).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data.objects);
            }
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with retrieving student courses. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
    
}

export default getModule(ProgressCourseStore);
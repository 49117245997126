import { GeneralResponse } from './generic';

export interface StudentItem {
  id: number;
  name: string;
  email: string;
  mobile: string;
  birthday: string;
  role: string;
  lastlogin: string;
}

export interface LiveSessionItem {
  name: string;
  date: string;
  duration: string;
  joined: string;
  left: string;
  attentionRate: number;
}

export interface QuizItem {
  name: string;
  date: string;
  totalTime: string;
  averageScore: string;
}

export interface HomeworkItem {
  name: string;
  startDate: string;
  dueDate: string;
  submissionDate: string;
  totalTime: string;
  averageScore: string;
}

export interface TestItem {
  testId: string;
  date: string;
  totalTime: string;
  averageScore: string;
}

export interface ParentNotificationItem {
  email: string;
  isActive: boolean;
}

export enum StudentProgressSection {
  purchasedCourses = `purchasedcourses`,
  homework = `homework`,
  parentNotification = `parent_notification`,
}

export interface ProgressFormItem {
  id: number;
  name: string;
}

export interface ProgressSubjectItem {
  id: number;
  name: string;
}

export interface ProgressTeacherItem {
  id: number;
  firstname: string;
  lastname: string;
}

export interface ProgressCourseItem {
  id: number;
  sku: number;
  startDate: string;
  courseName: string;
  form: ProgressFormItem;
  subject: ProgressSubjectItem;
  teacher: ProgressTeacherItem;
}

export interface ProgressCourseDataResponse {
  objects: ProgressCourseItem[];
}

export interface ProgressStudentHomeworkDetailItem {
  id: number;
  name: string;
  startDate: string;
  dueDate: string;
  submissionDate: string;
  totalTime: string;
  totalMarks: number;
  marks: number;
}

export interface ProgressStudentDetailResponse {
  objects: ProgressStudentHomeworkDetailItem[];
}

export interface ProgressResponse extends GeneralResponse {
  data: ProgressCourseDataResponse | ProgressStudentDetailResponse;
}

export interface StudentDetailProgressFetchParams {
  section: StudentProgressSection;
  studentId: string;
  courseId: string;
}

import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { StudentItem } from '@/models/progress';
import { CourseListItem } from '@/models/management';

@Module({
    namespaced: true,
    dynamic: true,
    name: `progress.state`,
    store,
})
class ProgressStateStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    studentState: StudentItem | null = null;
    courseState: CourseListItem | null = null;

    @Mutation
    updateStudentState(payload: StudentItem | null ) {
        this.studentState = payload;
    }

    @Mutation
    updateCourseState(payload: CourseListItem | null ) {
        this.courseState = payload;
    }

    @Action
    setStudentState(payload: StudentItem | null ) {
        this.context.commit(`updateStudentState`, payload);
    }

    @Action
    setCourseState(payload: CourseListItem | null ) {
        this.context.commit(`updateCourseState`, payload);
    }

    @Action
    resetStore() {
        this.context.commit(`updateStudentState`, null);
        this.context.commit(`updateCourseState`, null);
    }

}

export default getModule(ProgressStateStore);
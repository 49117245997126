import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic } from 'buefy';
import { EditReadingMaterialLevelName, ReadingMaterialItem, ReadingMaterialResponse, UpdateReadingMaterial } from '@/models/reading_material';

@Module({
    namespaced: true,
    dynamic: true,
    name: `reading_material.detail`,
    store,
})
class ReadingMaterialDetailStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: boolean | null = null;
    levelResponse: boolean | null = null;
    deleteResponse: boolean | null = null;
    fetchedCurrentMaterial: ReadingMaterialItem | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: boolean | null ) {
        this.response = payload;
    }

    @Mutation
    setLevelResponse(payload: boolean | null ) {
        this.levelResponse = payload;
    }

    @Mutation
    setDeleteResponse(payload: boolean | null ) {
        this.deleteResponse = payload;
    }

    @Mutation
    setCurrentMaterialResponse(payload: ReadingMaterialItem | null) {
        this.fetchedCurrentMaterial = payload;
    }

    @Action
    fetchCourseById(id: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setCurrentMaterialResponse`, null);
        axios.get<ReadingMaterialResponse>(`/media/material/${id}`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setCurrentMaterialResponse`, response.data.data);
            } else {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching the selected material. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    storeReadingMaterial(payload: FormData) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.post<ReadingMaterialResponse>(`/media/material/${this.fetchedCurrentMaterial!.id}`, payload)
            .then((response) => {
                if (response.data.code === 200 && response.data.message === `Success`) {
                    ToastProgrammatic.open({
                        type: `is-success`,
                        duration: 3000,
                        message: `Reading material successfully updated.`
                    });
                    this.context.commit(`setResponse`, true);
                }
    
                if (response.data.code >= 400) {
                    ToastProgrammatic.open({
                        type: `is-danger`,
                        duration: 3000,
                        message: `There is an issue updating reading materials. Please try again`
                    });
                }
            }).catch((error) => {
                this.context.commit(`setError`, error);
            });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    updateReadingMaterial(payload: ReadingMaterialItem) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.put<ReadingMaterialResponse>(`/media/material/${payload.id}`, {
            availability: payload.availability,
        })
            .then((response) => {
                if (response.data.code === 200 && response.data.message === `Success`) {
                    ToastProgrammatic.open({
                        type: `is-success`,
                        duration: 3000,
                        message: `Reading material successfully updated.`
                    });
                    this.context.commit(`setResponse`, true);
                }
    
                if (response.data.code >= 400) {
                    ToastProgrammatic.open({
                        type: `is-danger`,
                        duration: 3000,
                        message: `There is an issue updating reading materials. Please try again`
                    });
                }
            }).catch((error) => {
                this.context.commit(`setError`, error);
            });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    deleteReadingMaterial(payload: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setDeleteResponse`, null);
        axios.delete<ReadingMaterialResponse>(`/media/material/${payload}`,)
            .then((response) => {
                if (response.data.code === 200 && response.data.message === `Success`) {
                    ToastProgrammatic.open({
                        type: `is-success`,
                        duration: 3000,
                        message: `Reading material successfully deleted.`
                    });
                    this.context.commit(`setDeleteResponse`, true);
                }
    
                if (response.data.code >= 400) {
                    ToastProgrammatic.open({
                        type: `is-danger`,
                        duration: 3000,
                        message: `There is an issue deleting reading materials. Please try again`
                    });
                }
            }).catch((error) => {
                this.context.commit(`setError`, error);
            });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    deleteReadingMaterials(payload: number[]) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setDeleteResponse`, null);
        axios.delete<ReadingMaterialResponse>(`/media/material/batch`, {
            data: { material_ids: payload}
        })
            .then((response) => {
                if (response.data.code === 200 && response.data.message === `Success`) {
                    ToastProgrammatic.open({
                        type: `is-success`,
                        duration: 3000,
                        message: `Reading material successfully deleted.`
                    });
                    this.context.commit(`setDeleteResponse`, true);
                }
    
                if (response.data.code >= 400) {
                    ToastProgrammatic.open({
                        type: `is-danger`,
                        duration: 3000,
                        message: `There is an issue deleting reading materials. Please try again`
                    });
                }
            }).catch((error) => {
                this.context.commit(`setError`, error);
            });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    updateReadingMaterialLevel(payload: EditReadingMaterialLevelName) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setLevelResponse`, null);
        axios.put<ReadingMaterialResponse>(`/media/material/level`, {
            newLevel: payload.newName,
        }, {
            params: {
                level: payload.oldName,
            }
        }).then((response) => {
                if (response.data.code === 200 && response.data.message === `Success`) {
                    ToastProgrammatic.open({
                        type: `is-success`,
                        duration: 3000,
                        message: `Reading material level successfully updated.`
                    });
                    this.context.commit(`setLevelResponse`, true);
                }
    
                if (response.data.code >= 400) {
                    ToastProgrammatic.open({
                        type: `is-danger`,
                        duration: 3000,
                        message: `There is an issue updating reading material level. Please try again`
                    });
                }
            }).catch((error) => {
                this.context.commit(`setError`, error);
            });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(ReadingMaterialDetailStore);
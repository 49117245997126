import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast, ToastProgrammatic } from 'buefy';
import { CreateHomeworkItemForAxios, HomeworkResponse } from '@/models/homework';

@Module({
    namespaced: true,
    dynamic: true,
    name: `homework.create`,
    store,
})
class HomeworkCreateStore extends VuexModule {

    isLoading = false;
    error: any | null = null;
    response: any | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: any) {
        this.response = payload;
    }

    @Action
    createHomework(payload: CreateHomeworkItemForAxios) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.post<HomeworkResponse>(`/homework`, payload).then((response) => {
            if (response.data.code === 200) {
                ToastProgrammatic.open({
                    message: `Homework successfully created`,
                    duration: 3000,
                    type: `is-success`
                });
                this.context.commit(`setResponse`, response.data.data);
            }

            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    message: `There is an issue creating a homework. Please try again`,
                    duration: 3000,
                    type: `is-danger`
                });
            }
        }).catch((error) => {
            ToastProgrammatic.open({
                message: `There is an issue creating a homework. Please try again`,
                duration: 3000,
                type: `is-danger`
            });
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

}

export default getModule(HomeworkCreateStore);
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { QuestionDetailItemResponse, QuestionResponse } from '@/models/questions';
import { CreateQuestionBankQuestionForAxios, UpdateHomeworkQuestionForAxios, UpdateQuestionBankQuestionForAxios } from '@/models/question_banks';

@Module({
    namespaced: true,
    dynamic: true,
    name: `question.detail`,
    store,
})
class QuestionDetailStore extends VuexModule{
    isLoading = false;
    error: any | null = null;
    response: boolean | null = null;
    fetchedQuestionResponse: QuestionDetailItemResponse | null = null;
    questionId: number = -1;
    questionData: UpdateQuestionBankQuestionForAxios | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: boolean) {
        this.response = payload;
    }

    @Mutation
    setQuestionDataResponse(payload: QuestionDetailItemResponse) {
        this.fetchedQuestionResponse = payload;
    }

    @Mutation
    setQuestionId(payload: number) {
        this.questionId = payload;
    }

    @Mutation
    setQuestionData(payload: UpdateQuestionBankQuestionForAxios) {
        this.questionData = payload;
    }

    @Action
    retrieveQuestionBankQuestionById(payload: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setQuestionDataResponse`, null);
        this.context.commit(`setQuestionId`, payload);
        axios.get<QuestionResponse>(`/question/${payload}`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setQuestionDataResponse`, response.data.data);
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with retrieving the specified question. Please try again`,
                });
            }
        });

        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    updateQuestionBankQuestionById(payload: UpdateQuestionBankQuestionForAxios) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setQuestionData`, payload);
        const axiosPayload: CreateQuestionBankQuestionForAxios = {
            ...payload,
        };
        axios.put<QuestionResponse>(`/question/${payload.id}`, axiosPayload).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
                Toast.open({
                    type: `is-success`,
                    duration: 3000, // 3 Seconds
                    message: `Question has been successfully updated`,
                });
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with updating the question. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    deleteQuestionBankQuestionById(payload: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setQuestionId`, payload);
        axios.delete<QuestionResponse>(`/question/${payload}`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
                Toast.open({
                    type: `is-success`,
                    duration: 3000, // 3 Seconds
                    message: `Question has been successfully deleted`,
                });
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with deleting the question. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    retrieveHomeworkQuestionById(payload: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setQuestionDataResponse`, null);
        this.context.commit(`setQuestionId`, payload);
        axios.get<QuestionResponse>(`/homeworkquestion/${payload}`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setQuestionDataResponse`, response.data.data);
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with retrieving the specified question. Please try again`,
                });
            }
        });

        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    updateHomeworkQuestionById(payload: UpdateHomeworkQuestionForAxios) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setQuestionData`, payload);
        const axiosPayload: UpdateHomeworkQuestionForAxios = {
            ...payload,
        };
        axios.put<QuestionResponse>(`/homeworkquestion/${payload.id}`, axiosPayload).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
                Toast.open({
                    type: `is-success`,
                    duration: 3000, // 3 Seconds
                    message: `Homework question has been successfully updated`,
                });
            } 
            
            const invalidAnswerSelectionError = "Coding error detected, it must be fixed by a programmer: $result->noticeyesno no longer supported in save_question.";
            if (response.data.code === 500 && response.data.error === invalidAnswerSelectionError) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `Selected Answers does not match with One or Multiple Answers Selection. Please try again`,
                });
            } else if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with updating the question. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    deleteHomeworkQuestionById(payload: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setQuestionId`, payload);
        axios.delete<QuestionResponse>(`/question/${payload}`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
                Toast.open({
                    type: `is-success`,
                    duration: 3000, // 3 Seconds
                    message: `Question has been successfully deleted`,
                });
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with deleting the question. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(QuestionDetailStore);
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { FolderItem } from '@/models/miscellaneous';
import { GeneralListItem, QuestionBankListItem } from '@/models/management';
import { ChapterDisplayItem } from '@/models/chapters';
import { PaginationMemory } from '@/models/generic';

@Module({
    namespaced: true,
    dynamic: true,
    name: `question.state`,
    store,
})
class QuestionStateStore extends VuexModule {
    isLoading = false;
    isModified = false;
    error: any | null = null;
    questionBankManagementFolderState: FolderItem[] = [];
    subjectData: GeneralListItem[] = [];
    formData: GeneralListItem[] = [];
    chapterData: ChapterDisplayItem[] = [];
    questionData: QuestionBankListItem[] = [];
    currentPageState: { [key : string] : number } = {};
    currentLimitState: { [key : string] : number } = {};
    currentKeywordState: { [key : string] : string } = {};
    currentTotalCountState: { [key : string] : number } = {};
    
    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    setIsModified(payload: boolean) {
        this.isModified = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    saveQuestionBankManagementFolderState(folders: FolderItem[]) {
        this.questionBankManagementFolderState = folders;
    }

    @Mutation
    saveQuestionBankManagementSubjectState(payload: GeneralListItem[]) {
        this.subjectData = payload;
    }

    @Mutation
    saveQuestionBankManagementFormState(payload: GeneralListItem[]) {
        this.formData = payload;
    }

    @Mutation
    saveQuestionBankManagementChapterState(payload: ChapterDisplayItem[]) {
        this.chapterData = payload;
    }

    @Mutation
    saveQuestionBankManagementQuestionState(payload: QuestionBankListItem[]) {
        this.questionData = payload;
    }

    @Mutation
    saveQuestionBankManagementCurrentPage(payload: PaginationMemory) {
        this.currentPageState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveQuestionBankManagementCurrentLimit(payload: PaginationMemory) {
        this.currentLimitState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveQuestionBankManagementCurrentTotalCount(payload: PaginationMemory) {
        this.currentTotalCountState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveQuestionBankManagementCurrentKeyword(payload: PaginationMemory) {
        this.currentKeywordState[payload.folderLevel] = payload.value as string;
    }

    @Mutation
    resetQuestionBankManagementPaginationState() {
        this.currentPageState = {};
        this.currentLimitState = {};
        this.currentTotalCountState = {};
        this.currentKeywordState = {};
    }

    // Actions
    @Action
    updateQuestionBankManagementIsModified(payload: boolean) {
        this.context.commit(`setIsModified`, payload);
    }

    @Action
    updateQuestionBankManagementFolderState(folders: FolderItem[]) {
        this.context.commit(`saveQuestionBankManagementFolderState`, folders);
    }

    @Action
    updateQuestionBankManagementSubjectState(payload: GeneralListItem[]) {
        this.context.commit(`saveQuestionBankManagementSubjectState`, payload);
    }

    @Action
    updateQuestionBankManagementFormState(payload: GeneralListItem[]) {
        this.context.commit(`saveQuestionBankManagementFormState`, payload);
    }

    @Action
    updateQuestionBankManagementChapterState(payload: ChapterDisplayItem[]) {
        this.context.commit(`saveQuestionBankManagementChapterState`, payload);
    }

    @Action
    updateQuestionBankManagementQuestionState(payload: QuestionBankListItem[]) {
        this.context.commit(`saveQuestionBankManagementQuestionState`, payload);
    }

    @Action
    updateQuestionBankManagementCurrentPage(payload: PaginationMemory) {
        this.context.commit(`saveQuestionBankManagementCurrentPage`, payload);
    }

    @Action
    updateQuestionBankManagementCurrentLimit(payload: PaginationMemory) {
        this.context.commit(`saveQuestionBankManagementCurrentLimit`, payload);
    }

    @Action
    updateQuestionBankManagementCurrentTotalCount(payload: PaginationMemory) {
        this.context.commit(`saveQuestionBankManagementCurrentTotalCount`, payload);
    }

    @Action
    updateQuestionBankManagementCurrentKeyword(payload: PaginationMemory) {
        this.context.commit(`saveQuestionBankManagementCurrentKeyword`, payload);
    }

    @Action
    resetState() {
        this.context.commit(`setIsModified`, false);
        this.context.commit(`saveQuestionBankManagementFolderState`, []);
        this.context.commit(`saveQuestionBankManagementSubjectState`, []);
        this.context.commit(`saveQuestionBankManagementFormState`, []);
        this.context.commit(`saveQuestionBankManagementChapterState`, []);
        this.context.commit(`saveQuestionBankManagementQuestionState`, []);
        this.context.commit(`resetQuestionBankManagementPaginationState`);
    }

}

export default getModule(QuestionStateStore);
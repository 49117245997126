import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { CourseItemResponse, CourseItemUpdateRequestForAxios, CourseListDataResponse, CourseListResponse } from '@/models/courses';
import { ToastProgrammatic } from 'buefy';
import Axios from 'axios';
@Module({
    namespaced: true,
    dynamic: true,
    name: `courses.detail`,
    store,
})
class CourseDetailStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: CourseListDataResponse | null = null;
    currentCourse: CourseItemUpdateRequestForAxios | null = null;
    fetchedCurrentCourse: CourseItemResponse | null = null;
    currentId: number = -1;
    isCourseNameDuplicated = false;
    isTimeout: boolean | null = null;
    
    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: CourseListDataResponse | null) {
        this.response = payload;
    }

    @Mutation
    setIsCourseNameDuplicated(payload: boolean) {
        this.isCourseNameDuplicated = payload;
    }

    @Mutation
    setCurrentCourseResponse(payload: CourseItemResponse | null) {
        this.fetchedCurrentCourse = payload;
    }

    @Mutation
    setCurrentCourse(payload: CourseItemUpdateRequestForAxios | null) {
        this.currentCourse = payload;
    }

    @Mutation
    setCurrentCourseId(payload: number) {
        this.currentId = payload;
    }

    @Mutation
    setTimeOut(payload: boolean | null) {
        this.isTimeout = payload;
    }

    @Action
    updateIsCourseNameDuplicated(payload: boolean) {
        this.context.commit(`setIsCourseNameDuplicated`, payload);
    }

    @Action
    fetchCourseById(id: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setCurrentCourseResponse`, null);
        axios.get<CourseListResponse>(`/course/${id}`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setCurrentCourseResponse`, response.data.data.objects[0]);
            } else {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching the selected course. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    updateCurrentCourse(payload: Partial<CourseItemUpdateRequestForAxios>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setCurrentCourse`, {...payload});
        this.context.commit(`setResponse`, null);
        this.context.commit(`setIsCourseNameDuplicated`, false);
        this.context.commit(`setTimeOut`, null);

        axios.put<CourseListResponse>(`/course/${payload.id}`, {
            ...payload,
        }, {
            timeout: 60000
        }).then((response) => {
            if (response.data && response.data.code === 200) {
                ToastProgrammatic.open({
                    type: `is-success`,
                    duration: 3000, 
                    message: `Course successfully update!`,
                });
                this.context.commit(`setResponse`, response.data);
            } 

            if (response.data && response.data.code === 500 && response.data.error!.includes(`Short name is already used for another course`)) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 7000, // 3 Seconds
                    message: `${response.data.error}. Please enter a unique name.`,
                });
                this.context.commit(`setIsCourseNameDuplicated`, true);
            }
            
            if (response.data && response.data.code === 500) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 7000, // 7 Seconds
                    message: `There is an issue with course update process. Please try again. Error: ${response.data.message}`,
                });
            }
            
            if (JSON.stringify(response).includes('ECONNABORTED')) {
                this.context.commit(`setTimeOut`, true);
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 10000, // 10 Seconds
                    message: `The process has timed out. Please refresh and try again`,
                });
            }
            
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    deleteCurrentCourse(payload: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setCurrentCourseId`, payload);
        this.context.commit(`setResponse`, null);
        axios.delete<CourseListResponse>(`/course/${payload}`).then((response) => {
            if (response.data.code === 200) {
                ToastProgrammatic.open({
                    type: `is-info`,
                    duration: 3000, 
                    message: `Course successfully deleted!`,
                });
                this.context.commit(`setResponse`, response.data);
            } 
            
            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with course delete process. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(CourseDetailStore);
import QuestionCreateStore from './question.create';
import QuestionListStore from './question.list';
import QuestionDetailStore from './question.detail';
import QuestionModerationStore from './question.moderation';
import QuestionStateStore from './question.state';

export const QuestionStores = {
    create: QuestionCreateStore,
    list: QuestionListStore,
    detail: QuestionDetailStore,
    moderation: QuestionModerationStore,
    state: QuestionStateStore,
};
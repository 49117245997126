import HomeworkCreateStore from './homework.create';
import HomeworkListStore from './homework.list';
import HomeworkDetailStore from './homework.detail';
import HomeworkStateStore from './homework.state';

export const HomeworkStores = {
    create: HomeworkCreateStore,
    list: HomeworkListStore,
    detail: HomeworkDetailStore,
    state: HomeworkStateStore,
};
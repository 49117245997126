import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic } from 'buefy';
import { LectureResponse, LecturePriceUpdateRequest } from '@/models/lectures';

@Module({
    namespaced: true,
    dynamic: true,
    name: `lectures.detail`,
    store,
})
class LectureDetailStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: boolean | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: boolean | null) {
        this.response = payload;
    }

    @Action
    updateLecture(payload: Partial<LecturePriceUpdateRequest>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        // TODO: Replace this when we have a better api
        axios.post<LectureResponse>(`/media/kaltura`, payload).then((response) => {
            if (response.data.data && response.data.code === 200) {
                ToastProgrammatic.open({
                    type: `is-success`,
                    duration: 3000,
                    message: `Lecture has successfully updated`,
                });
                this.context.commit(`setResponse`, response.data.data);
            }

            if (response.data.code >= 400) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000,
                    message: `There is an issue update the specified lecture. Please try again`
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(LectureDetailStore);
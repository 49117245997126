import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { HomeworkResponse, UpdateHomeworkItemForAxios } from '@/models/homework';
import { ToastProgrammatic } from 'buefy';

@Module({
    namespaced: true,
    dynamic: true,
    name: `homework.detail`,
    store,
})
class HomeworkDetailStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: any | null = null;
    currentHomework: UpdateHomeworkItemForAxios | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: any) {
        this.response = payload;
    }

    @Mutation
    setCurrentHomework(payload: UpdateHomeworkItemForAxios | null) {
        this.currentHomework = payload;
    }

    @Action
    fetchHomeworkById(homeworkId: string) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setCurrentHomework`, null);
        axios.get(`/homework/${homeworkId}`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setCurrentHomework`, response.data.data);
            }

            if (response.data.code === 404) {
                ToastProgrammatic.open({
                    message: `The homework cannot be found`,
                    duration: 3000,
                    type: `is-danger`
                });
            }

            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    message: `There is an issue with fetching the current homework. Please try again`,
                    duration: 3000,
                    type: `is-danger`
                });
            }
        }).catch((error) => {
            ToastProgrammatic.open({
                message: `There is an issue with fetching the current homework. Please try again`,
                duration: 3000,
                type: `is-danger`
            });
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    updateHomework(payload: UpdateHomeworkItemForAxios) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.put<HomeworkResponse>(`/homework/${payload.id}`, payload).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
                ToastProgrammatic.open({
                    message: `Homework is successfully updated`,
                    duration: 3000,
                    type: `is-success`
                });
            }

            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    message: `There is an issue with updating a homework. Please try again`,
                    duration: 3000,
                    type: `is-danger`
                });
            }
        }).catch((error) => {
            ToastProgrammatic.open({
                message: `There is an issue with updating a homework. Please try again`,
                duration: 3000,
                type: `is-danger`
            });
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    deleteHomeworkById(homeworkId: number) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.delete<HomeworkResponse>(`/homework/${homeworkId}`).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
                ToastProgrammatic.open({
                    message: `Homework is successfully deleted`,
                    duration: 3000,
                    type: `is-info`
                });
            }

            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    message: `There is an issue with deleting a homework. Please try again`,
                    duration: 3000,
                    type: `is-danger`
                });
            }
        }).catch((error) => {
            ToastProgrammatic.open({
                message: `There is an issue with deleting a homework. Please try again`,
                duration: 3000,
                type: `is-danger`
            });
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(HomeworkDetailStore);
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic } from 'buefy';
import { FetchLectureParams, LectureListDataResponse, LectureResponse } from '@/models/lectures';

@Module({
    namespaced: true,
    dynamic: true,
    name: `lectures.list`,
    store,
})
class LectureListStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: LectureListDataResponse | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: LectureListDataResponse | null) {
        this.response = payload;
    }

    @Action
    retrieveLectures(params: Partial<FetchLectureParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        // TODO: Replace this when we have a better api
        axios.get<LectureResponse>(`/media/recorded`, {
            params: params
        }).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
            }

            if (response.data.code >= 400) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000,
                    message: `There is an issue fetching lectures. Please try again`
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(LectureListStore);
import ReadingMaterialListStore from './reading_material.list';
import ReadingMaterialCreateStore from './reading_material.create';
import ReadingMaterialStateStore from './reading_material.state';
import ReadingMaterialDetailStore from './reading_material.detail';

export const ReadingMaterialStores = {
    list: ReadingMaterialListStore,
    create: ReadingMaterialCreateStore,
    state: ReadingMaterialStateStore,
    detail: ReadingMaterialDetailStore,
};
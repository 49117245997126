import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic } from 'buefy';
import { FetchReadingMaterialLevelsParams, ReadingMaterialDataResponse, ReadingMaterialFetchParams, ReadingMaterialLevelDataResponse, ReadingMaterialLevelResponse, ReadingMaterialResponse } from '@/models/reading_material';

@Module({
    namespaced: true, 
    dynamic: true,
    name: `reading_materials.list`,
    store,
})
class ReadingMaterialListStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: ReadingMaterialDataResponse | null = null;
    levelResponse: ReadingMaterialLevelDataResponse | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: ReadingMaterialDataResponse | null) {
        this.response = payload;
    }

    @Mutation
    setLevelResponse(payload: ReadingMaterialLevelDataResponse | null) {
        this.levelResponse = payload;
    }

    @Action
    retrieveReadingMaterials(params: Partial<ReadingMaterialFetchParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.get<ReadingMaterialResponse>(`/media/material`, {
            params
        }).then((response) => {
            if (response.data.code === 200 && typeof response.data.data !== 'boolean') {
                this.context.commit(`setResponse`, response.data.data);
            }

            if (response.data.code >= 400) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000,
                    message: `There is an issue fetching reading materials. Please try again`
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    retrieveReadingMaterialLevelsWithParams(params: Partial<FetchReadingMaterialLevelsParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setLevelResponse`, null);
        axios.get<ReadingMaterialLevelResponse>(`/media/material/level`, {
            params: params
        }).then((response) => {
            if (response.data.code === 200 && typeof response.data.data !== 'boolean') {
                this.context.commit(`setLevelResponse`, response.data.data);
            }

            if (response.data.code >= 400) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000,
                    message: `There is an issue fetching reading material levels. Please try again`
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    retrieveReadingMaterialLevels() {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setLevelResponse`, null);
        axios.get<ReadingMaterialLevelResponse>(`/media/material/level`).then((response) => {
            if (response.data.code === 200 && typeof response.data.data !== 'boolean') {
                this.context.commit(`setLevelResponse`, response.data.data);
            }

            if (response.data.code >= 400) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000,
                    message: `There is an issue fetching reading material levels. Please try again`
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(ReadingMaterialListStore);
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  QuestionBankDetailedItemResponse,
  QuestionBankListResponse,
  UpdateQuestionBankListForAxios,
} from '@/models/question_banks';

@Module({
  namespaced: true,
  dynamic: true,
  name: `question_bank.detail`,
  store,
})
class QuestionBankDetailStore extends VuexModule {
  isLoading = false;
  error: any | null = null;
  response: boolean | null = null;
  fetchedQuestionResponse: QuestionBankDetailedItemResponse | null = null;
  questionId: number = -1;
  questionData: UpdateQuestionBankListForAxios | null = null;

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setResponse(payload: boolean) {
    this.response = payload;
  }

  @Mutation
  setQuestionBankDataResponse(payload: QuestionBankDetailedItemResponse) {
    this.fetchedQuestionResponse = payload;
  }

  @Mutation
  setQuestionId(payload: number) {
    this.questionId = payload;
  }

  @Mutation
  setQuestionData(payload: UpdateQuestionBankListForAxios) {
    this.questionData = payload;
  }

  @Action
  retrieveQuestionBankById(payload: number) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setQuestionBankDataResponse`, null);
    this.context.commit(`setQuestionId`, payload);
    axios.get<QuestionBankListResponse>(`/questionbank/getone/${payload}`).then((response) => {
      if (response.data.code === 200) {
        this.context.commit(`setQuestionBankDataResponse`, response.data.data);
      } 
      
      if (response.data.code === 500) {
        Toast.open({
          type: `is-danger`,
          duration: 3000, // 3 Seconds
          message: `There is an issue with retrieving the specified question bank. Please try again`,
        });
      }
    });

    this.context.commit(`updateLoadingStatus`, false);
  }

  @Action
  updateQuestionBankById(payload: Partial<UpdateQuestionBankListForAxios>) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    this.context.commit(`setQuestionData`, payload);
    const axiosPayload: Partial<UpdateQuestionBankListForAxios> = {
      name: payload.name as string,
      description: payload.description as string,
      subjectId: payload.subjectId as number,
      formId: payload.formId as number,
      tuitionId: 1,
      discountPrice: payload.discountPrice as number,
      price: payload.price as number,
      availability: payload.availability as number,
      image: payload.image as string,
      sku: payload.sku as string,
      questionType: payload.questionType,
    };
    axios
      .put<QuestionBankListResponse>(`/questionbank/${payload.id}`, axiosPayload)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`setResponse`, response.data.data);
          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Question Bank has been successfully updated`,
          });
        } 
        
        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with updating the current question bank. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }

  @Action
  deleteQuestionBankById(payload: number) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    this.context.commit(`setQuestionId`, payload);
    axios
      .delete<QuestionBankListResponse>(`/questionbank/${payload}`)
      .then((response) => {
        if (response.data.code === 200) {
          this.context.commit(`setResponse`, response.data.data);
          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Question Bank has been successfully deleted`,
          });
        } 
        
        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with deleting the question bank. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }
}

export default getModule(QuestionBankDetailStore);

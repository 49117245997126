import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { ParentNotificationItem, ProgressResponse, ProgressStudentDetailResponse, ProgressStudentHomeworkDetailItem, StudentDetailProgressFetchParams, StudentProgressSection } from '@/models/progress';

@Module({
    namespaced: true,
    dynamic: true,
    name: `progress.detail`,
    store,
})
class ProgressDetailStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    homeworkResponse: ProgressStudentHomeworkDetailItem[] = [];
    notificationResponse: ParentNotificationItem | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setHomeworkResponse(data: ProgressStudentHomeworkDetailItem[]) {
        this.homeworkResponse = data;
    }

    @Mutation
    setNotificationResponse(data: ParentNotificationItem | null ) {
        this.notificationResponse = data;
    }

    @Action
    resetResponses() {
        this.context.commit(`setHomeworkResponse`, []);
        this.context.commit(`setNotificationResponse`, null);
    }

    @Action
    retrieveSectionData(params: Partial<StudentDetailProgressFetchParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);

        switch(params.section) {
            case StudentProgressSection.parentNotification: {
                this.context.commit(`setNotificationResponse`, null);
                break;
            }
            case StudentProgressSection.homework: {
                this.context.commit(`setHomeworkResponse`, []);
                break;
            }
            default: {
                break;
            }
        }

        switch(params.section) {
            case StudentProgressSection.parentNotification: {
                this.context.commit(`setNotificationResponse`, null);
                axios.get(`/notification`, {
                    params: {
                        user_id: params.studentId,
                    }
                }).then((response) => {
                    if (response.data.code === 200) {
                        this.context.commit(`setNotificationResponse`, {section: params.section, data: response.data});
                    }
                    
                    if (response.data.code === 500) {
                        Toast.open({
                            type: `is-danger`,
                            duration: 3000, // 3 Seconds
                            message: `There is an issue with retrieving parents notification. Please try again`,
                        });
                    }
                }).catch((error) => {
                    this.context.commit(`setError`, error);
                });
                break;
            }
            default: {
                axios.get<ProgressResponse>(`/studentprogress`, {
                    params: {
                        section: params.section,
                        course_id: params.courseId,
                        user_id: params.studentId,
                    }
                }).then((response) => {
                    if (response.data.code === 200) {
                        this.context.commit(`setHomeworkResponse`, response.data.data.objects);
                    }
                    
                    if (response.data.code === 500) {
                        Toast.open({
                            type: `is-danger`,
                            duration: 3000, // 3 Seconds
                            message: `There is an issue with retrieving students ${params.section} data. Please try again`,
                        });
                    }
                }).catch((error) => {
                    this.context.commit(`setError`, error);
                });
                break;
            }
        }
    }

}

export default getModule(ProgressDetailStore);
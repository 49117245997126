import CourseListStore from './courses.list';
import CourseCreateStore from './courses.create';
import CourseDetailStore from './courses.details';
import CourseStateStore from './courses.state';

export const CourseStores = {
    list: CourseListStore,
    create: CourseCreateStore,
    details: CourseDetailStore,
    state: CourseStateStore,
};
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { FolderItem } from '@/models/miscellaneous';
import { CourseListItem } from '@/models/management';
import { ReadingMaterialItem } from '@/models/reading_material';
import { PaginationMemory } from '@/models/generic';

@Module({
    namespaced: true,
    dynamic: true,
    name: `reading_material.state`,
    store,
})
class ReadingMaterialStateStore extends VuexModule {
    isLoading = false;
    isModified = false;
    error: any | null = null;
    readingMaterialFolderState: FolderItem[] = [];
    courseData: CourseListItem[] = [];
    levelData: string[] = [];
    readingMaterialData: ReadingMaterialItem[] = [];
    currentPageState: { [key : string] : number } = {};
    currentLimitState: { [key : string] : number } = {};
    currentKeywordState: { [key : string] : string } = {};
    currentTotalCountState: { [key : string] : number } = {};

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    setIsModified(payload: boolean) {
        this.isModified = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    saveReadingMaterialFolderState(payload: FolderItem[]) {
        this.readingMaterialFolderState = payload;
    }

    @Mutation
    saveReadingMaterialCourseState(payload: CourseListItem[]) {
        this.courseData = payload;
    }

    @Mutation
    saveReadingMaterialLevelState(payload: string[]) {
        this.levelData = payload;
    } 
    
    @Mutation
    saveReadingMaterialDataState(payload: ReadingMaterialItem[]) {
        this.readingMaterialData = payload;
    }

    @Mutation
    saveReadingMaterialCurrentPage(payload: PaginationMemory) {
        this.currentPageState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveReadingMaterialCurrentLimit(payload: PaginationMemory) {
        this.currentLimitState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveReadingMaterialCurrentTotalCount(payload: PaginationMemory) {
        this.currentTotalCountState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveReadingMaterialCurrentKeyword(payload: PaginationMemory) {
        this.currentKeywordState[payload.folderLevel] = payload.value as string;
    }

    @Action
    updateReadingMaterialIsModified(payload: boolean) {
        this.context.commit(`setIsModified`, payload);
    }

    @Action
    updateReadingMaterialFolderState(payload: FolderItem[]) {
        this.context.commit(`saveReadingMaterialFolderState`, payload);
    }

    @Action
    updateReadingMaterialCourseState(payload: CourseListItem[]) {
        this.context.commit(`saveReadingMaterialCourseState`, payload);
    }

    @Action
    updateReadingMaterialLevelState(payload: string[]) {
        this.context.commit(`saveReadingMaterialLevelState`, payload);
    }

    @Action
    updateReadingMaterialState(payload: ReadingMaterialItem[]) {
        this.context.commit(`saveReadingMaterialDataState`, payload);
    }

    @Action
    updateReadingMaterialCurrentPage(payload: PaginationMemory) {
        this.context.commit(`saveReadingMaterialCurrentPage`, payload);
    }

    @Action
    updateReadingMaterialCurrentLimit(payload: PaginationMemory) {
        this.context.commit(`saveReadingMaterialCurrentLimit`, payload);
    }

    @Action
    updateReadingMaterialCurrentTotalCount(payload: PaginationMemory) {
        this.context.commit(`saveReadingMaterialCurrentTotalCount`, payload);
    }

    @Action
    updateReadingMaterialCurrentKeyword(payload: PaginationMemory) {
        this.context.commit(`saveReadingMaterialCurrentKeyword`, payload);
    }

    @Action
    resetState() {
        this.updateReadingMaterialCourseState([]);
        this.updateReadingMaterialFolderState([]);
        this.updateReadingMaterialLevelState([]);
        this.updateReadingMaterialState([]);
        this.updateReadingMaterialIsModified(false);
        this.context.commit(`saveReadingMaterialCurrentKeyword`, {});
        this.context.commit(`saveReadingMaterialCurrentLimit`, {});
        this.context.commit(`saveReadingMaterialCurrentTotalCount`, {});
        this.context.commit(`saveReadingMaterialCurrentPage`, {});
    }
}

export default getModule(ReadingMaterialStateStore);
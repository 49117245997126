import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ReadingMaterialResponse } from '@/models/reading_material';
import { ToastProgrammatic } from 'buefy';

@Module({
    namespaced: true,
    dynamic: true,
    name: `reading_material.create`,
    store
})
class ReadingMaterialCreateStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: boolean | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: boolean) {
        this.response = payload;
    }

    @Action
    createReadingMaterial(payload: FormData) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.post<ReadingMaterialResponse>(`/media/material`, payload)
            .then((response) => {
                if (response.data.code === 200) {
                    ToastProgrammatic.open({
                        message: `Reading Material successfully created`,
                        duration: 3000,
                        type: `is-success`
                    });
                    this.context.commit(`setResponse`, response.data.data);
                }
    
                if (response.data.code === 500 && response.data.error?.includes(`This file is exist.`)) {
                    ToastProgrammatic.open({
                        message: `File exists. Please select a new file`,
                        duration: 3000,
                        type: `is-danger`
                    });
                }

                if (response.data.code === 500 && !response.data.error?.includes(`This file is exist.`)) {
                    ToastProgrammatic.open({
                        message: `There is an issue creating reading material. Please try again`,
                        duration: 3000,
                        type: `is-danger`
                    });
                }
            })
            .catch((error) => {
                ToastProgrammatic.open({
                    message: `There is an issue creating reading material. Please try again`,
                    duration: 3000,
                    type: `is-danger`
                });
                this.context.commit(`setError`, error);
            });
            this.context.commit(`updateLoadingStatus`, false);
    }

}

export default getModule(ReadingMaterialCreateStore);
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { HomeworkItemDataResponse, HomeworkResponse, RetrieveHomeworkParams } from '@/models/homework';

@Module({
    namespaced: true,
    dynamic: true,
    name: `homework.list`,
    store,
})
class HomeworkListStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: HomeworkItemDataResponse | null = null;
    totalSize = 0;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: any) {
        this.response = payload;
    }

    @Mutation
    setTotalResponseSize(payload: number) {
        this.totalSize = payload;
    }

    @Action
    retrieveHomeworks(payload: Partial<RetrieveHomeworkParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        axios.get<HomeworkResponse>(`/homework`, {
            params: payload,
        }).then((response) => {
            if (response.status === 200) {
                this.context.commit(`setTotalResponseSize`, response.headers[`x-total-records`]);
                this.context.commit(`setResponse`, response.data.data);
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(HomeworkListStore);
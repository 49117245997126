import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { FolderItem } from '@/models/miscellaneous';
import { CourseListItem, SubjectLevelListItem } from '@/models/management';
import { PaginationMemory } from '@/models/generic';

@Module({
    namespaced: true,
    dynamic: true,
    name: `course.state`,
    store,
})
class CourseStateStore extends VuexModule {
    isLoading = false;
    isModified = false;
    error: any | null = null;
    courseFolderState: FolderItem[] = [];
    subjectData: SubjectLevelListItem[] = [];
    formData: SubjectLevelListItem[] = [];
    courseData: CourseListItem[] = [];
    currentPageState: { [key : string] : number } = {};
    currentLimitState: { [key : string] : number } = {};
    currentKeywordState: { [key : string] : string } = {};
    currentTotalCountState: { [key : string] : number } = {};
    
    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    setIsModified(payload: boolean) {
        this.isModified = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    saveCourseFolderState(folders: FolderItem[]) {
        this.courseFolderState = folders;
    }

    @Mutation
    saveCourseSubjectState(payload: SubjectLevelListItem[]) {
        this.subjectData = payload;
    }

    @Mutation
    saveCourseFormState(payload: SubjectLevelListItem[]) {
        this.formData = payload;
    }

    @Mutation
    saveCoursesState(payload: CourseListItem[]) {
        this.courseData = payload;
    }

    @Mutation
    saveCourseCurrentPage(payload: PaginationMemory) {
        this.currentPageState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveCourseCurrentLimit(payload: PaginationMemory) {
        this.currentLimitState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveCourseCurrentTotalCount(payload: PaginationMemory) {
        this.currentTotalCountState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveCourseCurrentKeyword(payload: PaginationMemory) {
        this.currentKeywordState[payload.folderLevel] = payload.value as string;
    }

    @Action
    updateCourseIsModified(payload: boolean) {
        this.context.commit(`setIsModified`, payload);
    }

    @Action
    updateCourseFolderState(folders: FolderItem[]) {
        this.context.commit(`saveCourseFolderState`, folders);
    }

    @Action
    updateCourseSubjectState(payload: SubjectLevelListItem[]) {
        this.context.commit(`saveCourseSubjectState`, payload);
    }

    @Action
    updateCourseFormState(payload: SubjectLevelListItem[]) {
        this.context.commit(`saveCourseFormState`, payload);
    }

    @Action
    updateCoursesState(payload: CourseListItem[]) {
        this.context.commit(`saveCoursesState`, payload);
    }

    @Action
    updateCourseCurrentPage(payload: PaginationMemory) {
        this.context.commit(`saveCourseCurrentPage`, payload);
    }

    @Action
    updateCourseCurrentLimit(payload: PaginationMemory) {
        this.context.commit(`saveCourseCurrentLimit`, payload);
    }

    @Action
    updateCourseCurrentTotalCount(payload: PaginationMemory) {
        this.context.commit(`saveCourseCurrentTotalCount`, payload);
    }

    @Action
    updateCourseCurrentKeyword(payload: PaginationMemory) {
        this.context.commit(`saveCourseCurrentKeyword`, payload);
    }

    @Action
    resetState() {
        this.context.commit(`saveCourseFolderState`, []);
        this.context.commit(`saveCourseSubjectState`, []);
        this.context.commit(`saveCourseFormState`, []);
        this.context.commit(`saveCoursesState`, []);
        this.context.commit(`setIsModified`, false);
        this.context.commit(`saveCourseCurrentKeyword`, {});
        this.context.commit(`saveCourseCurrentLimit`, {});
        this.context.commit(`saveCourseCurrentTotalCount`, {});
        this.context.commit(`saveCourseCurrentPage`, {});
    }

}

export default getModule(CourseStateStore);
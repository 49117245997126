import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { FolderItem } from '@/models/miscellaneous';
import { CourseListItem } from '@/models/management';
import { PaginationMemory } from '@/models/generic';

@Module({
    namespaced: true,
    dynamic: true,
    name: `homework.state`,
    store,
})
class HomeworkStateStore extends VuexModule {
    isLoading = false;
    isModified = false;
    error: any | null = null;
    homeworkFolderState: FolderItem[] = [];
    courseData: CourseListItem[] = [];
    homeworkData: any = [];
    questionData: any = [];
    currentPageState: { [key : string] : number } = {};
    currentLimitState: { [key : string] : number } = {};
    currentKeywordState: { [key : string] : string } = {};
    currentTotalCountState: { [key : string] : number } = {};
    
    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    setIsModified(payload: boolean) {
        this.isModified = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    saveHomeworkFolderState(folders: FolderItem[]) {
        this.homeworkFolderState = folders;
    }

    @Mutation
    saveHomeworkCoursesState(payload: CourseListItem[]) {
        this.courseData = payload;
    }

    @Mutation
    saveHomeworksState(payload: any) {
        this.homeworkData = payload;
    }

    @Mutation
    saveHomeworkQuestionsState(payload: any) {
        this.questionData = payload;
    }

    @Mutation
    saveHomeworkCurrentPage(payload: PaginationMemory) {
        this.currentPageState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveHomeworkCurrentLimit(payload: PaginationMemory) {
        this.currentLimitState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveHomeworkCurrentTotalCount(payload: PaginationMemory) {
        this.currentTotalCountState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveHomeworkCurrentKeyword(payload: PaginationMemory) {
        this.currentKeywordState[payload.folderLevel] = payload.value as string;
    }

    @Mutation
    resetQuestionBankManagementPaginationState() {
        this.currentPageState = {};
        this.currentLimitState = {};
        this.currentTotalCountState = {};
        this.currentKeywordState = {};
    }

    // Actions
    @Action
    updateHomeworkIsModified(payload: boolean) {
        this.context.commit(`setIsModified`, payload);
    }

    @Action
    updateHomeworkFolderState(folders: FolderItem[]) {
        this.context.commit(`saveHomeworkFolderState`, folders);
    }

    @Action
    updateHomeworkCoursesState(payload: CourseListItem[]) {
        this.context.commit(`saveHomeworkCoursesState`, payload);
    }

    @Action
    updateHomeworksState(payload: any) {
        this.context.commit(`saveHomeworksState`, payload);
    }

    @Action
    updateHomeworkQuestionsState(payload: any) {
        this.context.commit(`saveHomeworkQuestionsState`, payload);
    }

    @Action
    updateHomeworkCurrentPage(payload: PaginationMemory) {
        this.context.commit(`saveHomeworkCurrentPage`, payload);
    }

    @Action
    updateHomeworkCurrentLimit(payload: PaginationMemory) {
        this.context.commit(`saveHomeworkCurrentLimit`, payload);
    }

    @Action
    updateHomeworkCurrentTotalCount(payload: PaginationMemory) {
        this.context.commit(`saveHomeworkCurrentTotalCount`, payload);
    }

    @Action
    updateHomeworkCurrentKeyword(payload: PaginationMemory) {
        this.context.commit(`saveHomeworkCurrentKeyword`, payload);
    }

    @Action
    resetState() {
        this.context.commit(`saveHomeworkFolderState`, []);
        this.context.commit(`saveHomeworkCoursesState`, []);
        this.context.commit(`saveHomeworkQuestionsState`, []);
        this.context.commit(`setIsModified`, false);
        this.context.commit(`resetQuestionBankManagementPaginationState`);
    }

}

export default getModule(HomeworkStateStore);
import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { FolderItem } from '@/models/miscellaneous';
import { QuestionBankItemResponse } from '@/models/question_banks';
import { PaginationMemory } from '@/models/generic';
import { GeneralListItem } from '@/models/management';

@Module({
    namespaced: true,
    dynamic: true,
    name: `question_bank.state`,
    store,
})
class QuestionBankStateStore extends VuexModule {
    isLoading = false;
    isModified = false;
    error: any | null = null;
    questionBankListingFolderState: FolderItem[] = [];
    subjectData: GeneralListItem[] = [];
    formData: GeneralListItem[] = [];
    questionBankData: QuestionBankItemResponse[] = [];
    currentPageState: { [key : string] : number } = {};
    currentLimitState: { [key : string] : number } = {};
    currentKeywordState: { [key : string] : string } = {};
    currentTotalCountState: { [key : string] : number } = {};
    
    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    setIsModified(payload: boolean) {
        this.isModified = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    saveQuestionBankListingFolderState(folders: FolderItem[]) {
        this.questionBankListingFolderState = folders;
    }

    @Mutation
    saveQuestionBankListingSubjectState(payload: GeneralListItem[]) {
        this.subjectData = payload;
    }

    @Mutation
    saveQuestionBankListingFormState(payload: GeneralListItem[]) {
        this.formData = payload;
    }

    @Mutation
    saveQuestionBankListingState(payload: QuestionBankItemResponse[]) {
        this.questionBankData = payload;
    }

    @Mutation
    saveQuestionBankListingCurrentPage(payload: PaginationMemory) {
        this.currentPageState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveQuestionBankListingCurrentLimit(payload: PaginationMemory) {
        this.currentLimitState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveQuestionBankListingCurrentTotalCount(payload: PaginationMemory) {
        this.currentTotalCountState[payload.folderLevel] = payload.value as number;
    }

    @Mutation
    saveQuestionBankListingCurrentKeyword(payload: PaginationMemory) {
        this.currentKeywordState[payload.folderLevel] = payload.value as string;
    }

    @Mutation
    resetQuestionBankListingPaginationState() {
        this.currentPageState = {};
        this.currentLimitState = {};
        this.currentTotalCountState = {};
        this.currentKeywordState = {};
    }

    // Actions

    @Action
    updateQuestionBankListingIsModified(payload: boolean) {
        this.context.commit(`setIsModified`, payload);
    }

    @Action
    updateQuestionBankListingFolderState(folders: FolderItem[]) {
        this.context.commit(`saveQuestionBankListingFolderState`, folders);
    }

    @Action
    updateQuestionBankListingSubjectState(payload: GeneralListItem[]) {
        this.context.commit(`saveQuestionBankListingSubjectState`, payload);
    }

    @Action
    updateQuestionBankListingFormState(payload: GeneralListItem[]) {
        this.context.commit(`saveQuestionBankListingFormState`, payload);
    }

    @Action
    updateQuestionBankListingState(payload: QuestionBankItemResponse[]) {
        this.context.commit(`saveQuestionBankListingState`, payload);
    }

    @Action
    updateQuestionBankListingCurrentPage(payload: PaginationMemory) {
        this.context.commit(`saveQuestionBankListingCurrentPage`, payload);
    }

    @Action
    updateQuestionBankListingCurrentLimit(payload: PaginationMemory) {
        this.context.commit(`saveQuestionBankListingCurrentLimit`, payload);
    }

    @Action
    updateQuestionBankListingCurrentTotalCount(payload: PaginationMemory) {
        this.context.commit(`saveQuestionBankListingCurrentTotalCount`, payload);
    }

    @Action
    updateQuestionBankListingCurrentKeyword(payload: PaginationMemory) {
        this.context.commit(`saveQuestionBankListingCurrentKeyword`, payload);
    }

    @Action
    resetState() {
        this.context.commit(`setIsModified`, false);
        this.context.commit(`saveQuestionBankListingFolderState`, []);
        this.context.commit(`saveQuestionBankListingSubjectState`, []);
        this.context.commit(`saveQuestionBankListingFormState`, []);
        this.context.commit(`saveQuestionBankListingState`, []);
        this.context.commit(`resetQuestionBankListingPaginationState`);
    }

}

export default getModule(QuestionBankStateStore);
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import {
  QuestionBankDataResponse,
  QuestionBankListFetchParams,
  QuestionBankListResponse,
} from '@/models/question_banks';

@Module({
  namespaced: true,
  dynamic: true,
  name: `question_bank.list`,
  store,
})
class QuestionBankListStore extends VuexModule {
  isLoading = false;
  error: any | null = null;
  response: QuestionBankDataResponse | null = null;

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setResponse(payload: QuestionBankDataResponse) {
    this.response = payload;
  }

  @Action
  retrieveQuestionBanks(params?: Partial<QuestionBankListFetchParams>) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    axios
      .get<QuestionBankListResponse>(`/questionbank/list`, {
        params: {
          ...params,
        },
      })
      .then((response) => {
        if (response.data.code === 200) {
          response.data.data.totalCount = response.headers[`x-total-records`];
          this.context.commit(`setResponse`, response.data.data);
        } 
        
        if (response.data.code === 500) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with fetching question bank data. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }
}

export default getModule(QuestionBankListStore);

import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { FetchHomeworkQuestionsParams, FetchQuestionBankQuestionParams, QuestionListDataResponse, QuestionResponse } from '@/models/questions';

@Module({
    namespaced: true,
    dynamic: true,
    name: `questions.list`,
    store,
})
class QuestionListStore extends VuexModule{
    isLoading = false;
    error: any | null = null;
    response: QuestionListDataResponse | null = null;
    chapterId: number | null = null;
    homeworkId: number | null = null;
    totalSize = 0;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: QuestionListDataResponse) {
        this.response = payload;
    }

    @Mutation
    setTotalResponseSize(payload: number) {
        this.totalSize = payload;
    }

    @Mutation
    setChapterId(payload: number) {
        this.chapterId = payload;
    }

    @Mutation
    setHomeworkId(payload: number) {
        this.homeworkId = payload;
    }

    @Action
    retrieveQuestionBankQuestionsWithParams(params: Partial<FetchQuestionBankQuestionParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setChapterId`, params.chapter_id);
        // This will not work since the API doesn't do what we need yet. Will be placed here for now
        axios.get<QuestionResponse>(`/question`, {
            params: {
                ...params,
            }
        }).then((response) => {
            if (response.data.code === 200) {
                // response.data is the HTTP's data response.
                this.context.commit(`setTotalResponseSize`, response.headers[`x-total-records`]);
                this.context.commit(`setResponse`, response.data.data);
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching question data. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    retrieveHomeworkQuestionsWithParams(params: Partial<FetchHomeworkQuestionsParams>) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setHomeworkId`, params.homework_id);
        axios.get<QuestionResponse>(`/homeworkquestion`, {
            params: {
                ...params,
            }
        }).then((response) => {
            if (response.data.code === 200) {
                // response.data is the HTTP's data response.
                this.context.commit(`setTotalResponseSize`, response.headers[`x-total-records`]);
                this.context.commit(`setResponse`, response.data.data);
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with fetching question data. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(QuestionListStore);
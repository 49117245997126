import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { CreateQuestionBankListForAxios } from '@/models/question_banks';
import { QuestionResponse } from '@/models/questions';

@Module({
  namespaced: true,
  dynamic: true,
  name: `question_bank.create`,
  store,
})
class QuestionBankCreateStore extends VuexModule {
  isLoading = false;
  error: any | null = null;
  response: boolean | null = null;
  questionBankProperties: CreateQuestionBankListForAxios | null = null;

  @Mutation
  setError(payload: any) {
    this.error = payload;
  }

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setResponse(payload: boolean | null) {
    this.response = payload;
  }

  @Mutation
  setQuestionBankProperties(payload: CreateQuestionBankListForAxios) {
    this.questionBankProperties = payload;
  }

  @Action
  createNewQuestionBank(payload: Partial<CreateQuestionBankListForAxios>) {
    this.context.commit(`updateLoadingStatus`, true);
    this.context.commit(`setError`, null);
    this.context.commit(`setResponse`, null);
    this.context.commit(`setQuestionBankProperties`, payload);
    axios
      .post<QuestionResponse>(`/questionbank`, payload)
      .then((response) => {
        if (response.data && response.data.code === 200) {
          this.context.commit(`setResponse`, response.data.data);
          Toast.open({
            type: `is-success`,
            duration: 3000, // 3 Seconds
            message: `Successfully created question bank`,
          });
        } 
        
        if (response.data.code === 500 && response.data.error && response.data.error.includes(`already created`)) {
          Toast.open({
            type: `is-danger`,
            duration: 6000, // 6 Seconds
            message: `${response.data.error} Select a new combination of Subject and Level`,
          });
        } 
        
        if (response.data.code === 500 && response.data.error && !response.data.error.includes(`already created`)) {
          Toast.open({
            type: `is-danger`,
            duration: 3000, // 3 Seconds
            message: `There is an issue with creating a Question Bank. Please try again`,
          });
        }
      })
      .catch((error) => {
        this.context.commit(`setError`, error);
      });
    this.context.commit(`updateLoadingStatus`, false);
  }
}

export default getModule(QuestionBankCreateStore);

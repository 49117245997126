









































import { Vue, Component, Emit } from 'vue-property-decorator';
import SearchBar from '@/components/common/SearchBar.vue';
import { axios } from '@/configs/axios';
import { ToastProgrammatic } from 'buefy';
import { UserStores } from '@/store/users';
import { CourseStores } from '@/store/course';
import { HomeworkStores } from '@/store/homeworks';
import { LectureStores } from '@/store/lectures';
import { ProgressStores } from '@/store/progress';
import { QuestionBankStores } from '@/store/question_banks';
import { QuestionStores } from '@/store/questions';
import { ReadingMaterialStores } from '@/store/readingMaterials';
@Component({
  components: { SearchBar },
})
export default class Nav extends Vue {
  isActive = false;
  isUserDropdownActive = false;
  userProfileStore = UserStores.profile;
  // State Stores
  courseStateStore = CourseStores.state;
  homeworkStateStore = HomeworkStores.state;
  lectureStateStore = LectureStores.state;
  progressStateStore = ProgressStores.state;
  questionBankStateStore = QuestionBankStores.state;
  questionStateStore = QuestionStores.state;
  readingMaterialStateStore = ReadingMaterialStores.state;

  get userName() {
    return this.userProfileStore.profileName;
  }

  get moduleName() {
    // Check the route to get the name
    return this.$route.meta?.category ? this.$route.meta!.category : ``;
  }

  redirectToProfile() {
    this.$router.push({
      name: `view_profile`,
    });
  }

  @Emit(`setActive`)
  showNavMenu() {
    this.isActive = !this.isActive;
    return this.isActive;
  }

  async logoutUser() {
    const response = await axios.post(`auth/logout`);
    if (response.data.code === 200) {
      ToastProgrammatic.open({
        type: `is-success`,
        duration: 3000,
        message: `Succesfully logged out!`,
      });
      // Reset Store States
      this.courseStateStore.resetState();
      this.homeworkStateStore.resetState();
      this.lectureStateStore.resetState();
      this.progressStateStore.resetStore();
      this.questionBankStateStore.resetState();
      this.questionStateStore.resetState();
      this.readingMaterialStateStore.resetState();

      UserStores.login.setIsLoggedIn(false);
      this.$router.push({
        path: '/login',
      });
    } else {
      ToastProgrammatic.open({
        type: `is-danger`,
        duration: 3000,
        message: `Failed to log out. Please try again`,
      });
    }
  }
}

import QuestionBankListStore from './question_bank.list';
import QuestionBankCreateStore from './question_bank.create';
import QuestionBankDetailStore from './question_bank.detail';
import QuestionBankStateStore from './question_bank.state';

export const QuestionBankStores = {
  list: QuestionBankListStore,
  create: QuestionBankCreateStore,
  detail: QuestionBankDetailStore,
  state: QuestionBankStateStore,
};























import { RouteItem } from '@/models/miscellaneous';
import { UserStores } from '@/store/users';
import { Roles } from '@/utils/constants';
import { Vue, Component, Prop } from 'vue-property-decorator';
@Component({
  components: {},
})
export default class SideNav extends Vue {
  @Prop()
  isActive!: boolean;

  userProfileStore = UserStores.profile;

  isCurrentRouteActive(currentPath: string) {
    return this.$route.path.includes(currentPath);
  }

  get rawRoutes(): RouteItem[] {
    return [
      {
        name: 'Dashboard',
        icon: require(`@/assets/icons/Dashboard.svg`),
        path: `/dashboard`,
        activeIcon: require(`@/assets/icons/Dashboard-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Timetable',
        icon: require(`@/assets/icons/Timetable.svg`),
        path: `/timetable`,
        activeIcon: require(`@/assets/icons/Timetable-Active.svg`),
        roles: [Roles.Teacher, Roles.Admin],
      },
      {
        name: 'Courses',
        icon: require(`@/assets/icons/Courses.svg`),
        path: `/courses`,
        activeIcon: require(`@/assets/icons/Courses-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Question Bank',
        icon: require(`@/assets/icons/Question-Bank.svg`),
        path: '/question_banks',
        activeIcon: require(`@/assets/icons/Question-Bank-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Resources',
        icon: require(`@/assets/icons/Resources.svg`),
        path: `/resources`,
        activeIcon: require(`@/assets/icons/Resources-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Orders',
        icon: require(`@/assets/icons/Orders.svg`),
        path: `/orders`,
        activeIcon: require(`@/assets/icons/Orders-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Users',
        icon: require(`@/assets/icons/Students.svg`),
        path: `/users`,
        activeIcon: require(`@/assets/icons/Students-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Progress',
        icon: require(`@/assets/icons/Progress.svg`),
        path: `/progress`,
        activeIcon: require(`@/assets/icons/Progress-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Homework',
        icon: require(`@/assets/icons/Homeworks.svg`),
        path: `/homeworks`,
        activeIcon: require(`@/assets/icons/Homeworks-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Events',
        icon: require(`@/assets/icons/Event.svg`),
        path: `/events`,
        activeIcon: require(`@/assets/icons/Event-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Banners',
        icon: require(`@/assets/icons/Banner.svg`),
        path: `/banners`,
        activeIcon: require(`@/assets/icons/Banner-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Rewards',
        icon: require(`@/assets/icons/Reward.svg`),
        path: `/rewards`,
        activeIcon: require(`@/assets/icons/Reward-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Badges',
        icon: require(`@/assets/icons/Badge.svg`),
        path: `/badges`,
        activeIcon: require(`@/assets/icons/Badge-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Notification',
        icon: require(`@/assets/icons/Notification.svg`),
        path: `/notification_settings`,
        activeIcon: require(`@/assets/icons/Notification-Active.svg`),
        roles: [Roles.Admin],
      },
      {
        name: 'Settings',
        icon: require(`@/assets/icons/Settings.svg`),
        path: `/settings`,
        activeIcon: require(`@/assets/icons/Settings-Active.svg`),
        roles: [Roles.Admin],
      },
    ];
  }

  get routes() {
    const currentRole = this.userProfileStore.profileRole;
    return this.rawRoutes.filter((data) => {
      return data.roles.includes(currentRole);
    });
  }
}
